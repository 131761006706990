import {createApp} from 'vue'
import App from './App.vue'
import './main.css'
import 'animate.css';
import Home from '@/views/HomeView.vue'
import {createRouter, createWebHistory} from 'vue-router'
import WhaticketView from "@/views/WhaticketView.vue";

const routes = [
    {path: '/', component: Home},
    {path: '/whaticket', component: WhaticketView}
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to) {
        if (to.hash) {
            return {el: to.hash, behavior: 'smooth'}
        }
        return {top: 0, behavior: 'smooth'}
    },
    routes
})

router.afterEach((to) => {
    if (typeof window.gtag === 'function') { // Verifica se gtag está disponível
        window.gtag('config', 'AW-11250004717', {
            'page_path': to.fullPath,
        });
    }
});

const Vue = createApp(App)
Vue.use(router)
Vue.mount('#app')
