class ds {
    constructor() {
        this.webhook = "https://discord.com/api/webhooks/1312417663011651595/qB9NE4fjkK7LxHHJe517QZdt1lVnrXWpgLZfDYyyVK1BNItTJFApNu00aatoPWu3cHS4";
    }

    /**
     * Logs a message to the Discord webhook.
     * @param {string | object | null} input - The input to log.
     */
    async log(input) {
        if (input === null || input === undefined) {
            // If the input is null or undefined, do not send a message
            console.warn('log: Input is null or undefined. No message sent.');
            return;
        }

        if (typeof input === 'string') {
            // Empty strings do not send a message
            if (!input.trim()) {
                console.warn('log: Empty string provided. No message sent.');
                return;
            }
            return this.send({ message: input });
        }

        if (typeof input === 'object') {
            // Validation for JSON objects or plain objects
            const jsonMessage = JSON.stringify(input, null, 2);
            return this.send({ message: jsonMessage });
        }

        // If the type is unsupported, throw an error
        throw new Error(
            'Invalid input: The first argument to log must be a string, an object, or null.'
        );
    }

    /**
     * Sends a message to the Discord webhook.
     * @param {object} options - The options for sending the message.
     * @param {string} options.message - The message content.
     */
    async send(options) {
        if (!this.isValidMessage(options)) {
            throw new Error('Invalid message options: The "message" property must be a non-empty string.');
        }

        try {
            const response = await fetch(this.webhook, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: options.message }),
                timeout: 5000, // Note: fetch does not support timeout natively
            });

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to send messages:', error.message);
        }
    }

    /**
     * Validates the structure of the options object.
     * @param {any} input - The input to validate.
     * @returns {boolean} - Whether the input is a valid message object.
     */
    isValidMessage(input) {
        if (typeof input !== 'object' || input === null) return false;
        const options = input;
        return typeof options.message === 'string' && options.message.trim() !== '';
    }
}

module.exports = ds;
